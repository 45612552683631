import {
    Abstract
} from './Abstract';

import axios from 'axios';

export class BWCybersourceBranchProcessor extends Abstract { 
    constructor(session) {
        super('BusinessWallet/CyberSource/Configuration/processor', session);
        this.processor_name = null;
        this.visa_mc_debit_rate = 0.00;
        this.visa_mc_credit_rate = 0.00;
        this.amex_credit_rate = 0.00;
        this.visa_mc_credit_surcharge_3 = 0.00;
        this.visa_mc_credit_surcharge_6 = 0.00;
        this.visa_mc_credit_surcharge_9 = 0.00;
        this.visa_mc_credit_surcharge_12 = 0.00;
        this.visa_mc_credit_surcharge_18 = 0.00;
        this.visa_mc_credit_surcharge_24 = 0.00;
        this.amex_credit_surcharge_3 = 0.00;
        this.amex_credit_surcharge_6 = 0.00;
        this.amex_credit_surcharge_9 = 0.00;
        this.amex_credit_surcharge_12 = 0.00;
        this.amex_credit_surcharge_18 = 0.00;
        this.amex_credit_surcharge_24 = 0.00;
        this.visa_mc_credit_surcharge_3_affiliation = null;
        this.visa_mc_credit_surcharge_6_affiliation = null;
        this.visa_mc_credit_surcharge_9_affiliation = null;
        this.visa_mc_credit_surcharge_12_affiliation = null;
        this.visa_mc_credit_surcharge_18_affiliation = null;
        this.visa_mc_credit_surcharge_24_affiliation = null;
        this.amex_credit_surcharge_3_affiliation = null;
        this.amex_credit_surcharge_6_affiliation = null;
        this.amex_credit_surcharge_9_affiliation = null;
        this.amex_credit_surcharge_12_affiliation = null;
        this.amex_credit_surcharge_18_affiliation = null;
        this.amex_credit_surcharge_24_affiliation = null;
        this.merchantID = null;
        this.runEnvironment = 'apitest.cybersource.com';
        this.portfolioID = null;
        this.useMetaKey = 'false';
        this.merchantsecretKey = null;
        this.merchantKeyId = null;
        this.income_tpv=null;
        this.total_amount_tpv=null;
        this.sponsor_bank=null;
      
    }

    async retrieveByBranchAndProcessor() {
        let response = await axios.get(this.controller + "/" + this.id_company + "/" + this.id_branch + "/" + this.processor_name);
        return response.data;
    }

    
    async allxDB(database,company,branch) {
        let response = await axios.get('BusinessWallet/CyberSource/Configuration/allxdb',  {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                company_ext:company,
                branch_ext:branch,
                database:database
            }
        });
        return response.data;
    }

    async saveAdminProcessor(data) {
        let response = await axios.post('BusinessWallet/CyberSource/Configuration/admin/processor', data, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }
}